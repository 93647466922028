<script>
  import {mapActions, mapState} from "vuex";

  /**
 * Login component
 */
export default {

  computed: {
    ...mapState({
      alert: state => state.alert
    })
  },
  methods: {
    ...mapActions("alert", {
      success: "success",
      error: "error"
    }),
    ...mapActions("auth", ["logout"]),
  },
  created() {
    // reset login status
    this.logout();
    this.success("Wylogowanie nastąpiło prawidłowo! Do zobaczenia!");
    this.$router.push({ name: 'Logowanie' })
  }
};
</script>

<template>
  <br>
</template>

<style lang="scss" module></style>
